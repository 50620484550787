<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_ser_atingido_title_diamond.png"
            alt=""
          />
          <h3 class="ml-3" style="color: #E06919">
            Estigma
          </h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_02.png"
            alt=""
          />
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="O estigma de ser atingido: potência e dor de uma identidade marcada"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Apesar de perturbadores, os atos de hostilidade e
                  estigmatização<sup>1</sup> para com as vítimas não são
                  incomuns em contextos de desastre. Em Goiânia, onde a abertura
                  de uma cápsula de Césio abandonada no antigo Instituto Goiano
                  de Radioterapia conduziu a um desastre radioativo, o
                  mapeamento das pessoas e espaços afetados contribuiu para a
                  configuração do “estigma de ser contaminado/irradiado” (SILVA,
                  2012, p. 504). Examinando a tragédia de Vargas, na Venezuela,
                  onde enchentes e deslizamentos de encostas, em 1999,
                  provocaram o deslocamento compulsório de 16.000 pessoas para o
                  estado de Zulia, Sandrine Revet (2002, p. 1) sublinhou a
                  rejeição enfrentada pela população realocada submetida à
                  convivência com vizinhos que não partilhavam “a memória da
                  catástrofe”. Nesse caso, o sofrimento provocado pelo
                  deslocamento compulsório foi agravado e acompanhado da
                  necessidade de negociar pertencimento e aceitação social. O
                  documentário de Spike Lee “Quando os Diques se Romperam” sobre
                  o furacão Katrina também problematizou a atuação das
                  instituições de reparação e da imprensa ao designarem as
                  vítimas como “refugiados”, indicando não apenas a privação da
                  moradia, dos bens e de seu território, mas de sua própria
                  cidadania. Ao revisitar fragmentos de dor e lembranças entre
                  os sobreviventes de Chernobyl, a usina nuclear cujo reator
                  explodira na Ucrânia em 1986, Svetlana Aleksiévitch (2016, p.
                  65-66), recolheu o seguinte depoimento de um pai:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Você vive como uma pessoa normal. Uma pessoa comum. Assim, como
            todo mundo à sua volta: vai ao trabalho e volta pra casa. Recebe um
            salário médio [...] Você tem mulher. Filhos. É uma pessoa normal! E
            de repente, de um dia para o outro, você se torna um homem de
            Tchernóbil. Um animal raro! Uma coisa que interessa a todo mundo,
            que ninguém conhece. Você quer ser como todas as pessoas, mas isso
            não é mais possível. Não há como voltar ao mundo anterior. Você
            passa a ser olhado de forma diferente. As pessoas lhe perguntam: “Lá
            foi tão terrível assim?” [...] Nos primeiros tempos, todos nós nos
            tornamos raridades em exposição [...]. Nós perdemos não a cidade,
            mas a nossa vida inteira.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Nessa narrativa, a passagem de um cenário familiar de
                  segurança e relativa estabilidade contrasta com a experiência
                  seguinte da perda, da incerteza e da contínua exposição à
                  avaliação e ao julgamento de outrem. Embora a mobilização de
                  marcadores seja expediente comum na construção das identidades
                  sociais, e ainda que tais sinais possibilitem a organização
                  das condutas nos diferentes encontros sociais, a
                  estigmatização revela certas condições extremas de invalidação
                  do status moral alheio (GOFFMAN, 1982). Nesse sentido, as
                  antigas reivindicações de identidade que davam sentido ao
                  mundo são desacreditadas ou colocadas em suspeição por parte
                  de atores ou instituições com os quais as vítimas passam a
                  interagir. Tal foi a dinâmica observada para o desastre
                  associado ao rompimento da barragem de Fundão, em Mariana-MG.
                </p>
                <p>
                  Para além dos territórios dilacerados, das histórias e
                  relações interrompidas, da imposição de uma nova rotina e da
                  experiência de uma vida deslocada e suspensa, os atingidos e
                  atingidas pelo desastre–crime enfrentaram um contexto hostil
                  ao acolhimento da sua presença e de seus esforços de
                  reivindicação de direitos. Essa hostilização pode ser notada
                  na forma de designações ofensivas que circularam na sede
                  urbana de Mariana, como a expressão “pés de lama”. Expressões
                  como essa foram acompanhadas de acusações diretas ou difusas
                  que imputaram aos atingidos e atingidas o fardo adicional da
                  rejeição e da recriminação.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_03.png"
            alt=""
          />
          <p style="text-align: center; font-size: 0.6em">
            Camiseta preta com os dizeres “Desrespeito, preconceito,
            discriminação, descaso, intolerância, desumanidade…” utilizada pelos
            atingidos de Benro Rodrigues no um ano do rompimento da Barragem.
            Fonte: Acervo GESTA/UFMG, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_04.png"
            alt=""
          />
          <p style="text-align: center; font-size: 0.6em">
            Faixa na cidade de Mariana em apoio ao retorno das atividades da
            Samarco no município. Empresa de consultoria na área de Gestão
            Empresarial consta como apoio. Foto: Acervo Gesta/UFMG, 2016.
          </p>
        </v-col>

        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A hostilidade esteve estampada em faixas espalhadas pelo
                  comércio da cidade poucos meses após o rompimento da barragem.
                  Nelas, os estabelecimentos anunciavam seu irrestrito apoio à
                  retomada das operações da mineradora. Um movimento político
                  chamado “Justiça Sim, Desemprego Não: Fica Samarco” foi
                  organizado com o propósito de intervir sobre a crítica pública
                  e cultivar um ambiente receptivo e simpático à empresa
                  responsável pela catástrofe. Na mesma direção, um proeminente
                  representante do legislativo estadual declarou que “o caos da
                  paralisação da empresa [era] é muito maior do que o acidente
                  em si”. Posicionamentos como esse contribuíram para fomentar
                  tensões em relação às mobilizações das vítimas, constituindo
                  uma atmosfera de recusa e intolerância em relação à presença e
                  às narrativas apresentadas pelos atingidos e atingidas.
                </p>
                <p>
                  Tal rejeição se materializou nas manifestações públicas de
                  censura às reivindicações dos atingidos e atingidas. Nesses
                  casos, a crítica sugeria ou declarava abertamente seu
                  julgamento moral sobre as vítimas, ao expor sua avaliação de
                  tais demandas como desproporcionais ou superlativas. Nesse
                  contexto, o recebimento de direitos como o Auxílio Financeiro
                  Emergencial foi interpretado como uma espécie de usurpação,
                  invertendo as posições de perpetrador e vítima, conforme
                  revela o diálogo seguinte entre dois moradores de Paracatu de
                  Baixo:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="10"
          class="d-flex flex-column border__image3 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “— Ele chamou a turma daqui de sem-vergonha. Você precisava ver. Ele
            pôs a turma daqui valendo nada.
          </p>
          <p>
            — É, eles xingam mesmo. Muita gente não fala. Eu mesmo faço questão
            de falar que nem que sou daqui, porque eles xingam o povo do Bento,
            de Paracatu, xingam mesmo. Teve uma vez que teve uma reunião com o
            promotor no fórum. Aí juntou um monte de gente... Teve um monte de
            gente de Bento, Paracatu. Aí eles passavam na rua e gritavam:
            "Cambada de vagabundo, vai caçar trabalho, tá caçando dinheiro, vai
            caçar serviço". No meio da rua assim...”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Destaca-se aqui a associação entre uma condição de ócio e o
                recebimento do chamado “cartão da Samarco”. Essa associação
                constituiu a base para a construção de uma identidade
                depreciativa atribuída às vítimas que foram alvo de rumores e
                desacreditação social. É preciso acentuar que as noções de
                “aproveitadores” ou “vagabundos” atingiram a ordem moral do
                grupo, em especial seu próprio sentido de dignidade e honra.
              </p>
              <p>
                Adicionalmente, as vítimas foram recriminadas por seus esforços
                de mobilização, os quais foram interpretados, muitas vezes, como
                entraves à retomada das operações da mineradora. Essa dinâmica
                de desmoralização das vítimas, se, de um lado, recrimina o
                pleito dos direitos a serem restaurados, por outro lado, auxilia
                na construção da desresponsabilização dos agentes empresariais,
                corroborando a leitura de que o rompimento da barragem pode ser
                reduzida a um infortúnio excepcional atribuído à eventual
                imperícia técnica que pode ser rapidamente retificada.
              </p>
              <p>
                A leitura dessa dinâmica de estigmatização permite perceber que
                além de enfrentarem perdas dos recursos, liberdades e
                territórios do seu mundo habitual devastado, as vítimas foram
                submetidas a provocações e ofensas verbais que procuraram
                invalidar sua condição e seus pleitos. Esse percurso foi também
                acompanhado pelo sentimento de contínua exposição involuntária,
                na medida em que os atingidos e atingidas perderam o controle
                sobre a integridade e intimidade de seus antigos lares, os quais
                se tornaram alvo de saqueadores e objeto de visita de curiosos,
                jornalistas e fotógrafos. A reserva de informação sobre a
                privacidade da família também foi violada, dada a necessidade,
                invariavelmente, de expor e registrar circunstâncias domésticas
                que poderiam ser relevantes para a organização das medidas de
                reparação. Tal sentimento de exposição foi traduzido no
                depoimento de uma liderança:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex flex-column border__image4 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “Aqui [Mariana] não se tem liberdade, não se dorme bem. A gente não
            pode nem usar a praga do cartão, porque isso não é um benefício,
            gente, é uma praga, eles olham e dizem ‘olha lá os aproveitadores’.
            As pessoas de lá [Paracatu] tinham berço, os outros pediam benção e
            licença para entrar em nossas casas... Agora se alguém morre vira
            notícia, se alguém fica triste vira notícia...”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Essa hostilidade difusa e a difamação que caracterizam a
                  imposição de um estigma podem ser vistas, no entanto, como
                  parte de uma estratégia mais ampla de deslegitimação da
                  crítica social, obstruindo a construção de uma imagem lesiva
                  da mineração. Nessa medida, a disputa dos afetos, das
                  narrativas e das memórias reveladas pela discriminação dos
                  atingidos e atingidas evidencia a tentativa de marginalizar no
                  campo discursivo a imagem da vítima, pois ela presentifica o
                  dano, denuncia a violência e coloca em pauta os direitos a
                  serem reparados.
                </p>
                <p>
                  Assim, para compreender a brutalidade de tais reações diante
                  do sofrimento das vítimas é preciso pontuar a força da “utopia
                  minerária” (ANTONELLI, 2009) que associa a mineração
                  exclusivamente ao crescimento econômico, ao aumento das
                  receitas e das rendas locais, silenciando e negando os riscos
                  e danos associados aos projetos. As promessas e expectativas
                  de progresso mobilizam afetos, emoções e intervêm sobre a
                  capacidade de tolerar a devastação e a violência,
                  transformando as localidades onde se implantam os projetos
                  minerários em cidades e povoados cindidos, minados desde o seu
                  interior, inclusive em sua sensibilidade frente à barbárie
                  (ARAOZ, 2014).
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_ser_atingido_05.png"
            alt=""
          />
          <p style="text-align: center; font-size: 0.6em">
            Ato dos atingidos no território atingido em Mariana em 15/11/2016,
            quando se deu um ano do desastre-crime. Na frente das camisetas
            pretas consta “Tomamos banho, mas a lama não sai”. Acervo
            GESTA/UFMG, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Todavia, em resposta à estigmatização, os atingidos e atingidas
                seguem denunciando a continuidade do desastre. Inventivamente e
                com perspicácia política, as vítimas pautam a visibilidade dos
                danos. No evento ‘Um Ano de Lama, Um Ano de Luta’, organizado
                pelas vítimas, movimentos apoiadores e outras organizações
                sociais, muitos atingidos e atingidas portavam uma camiseta
                preta. A camiseta que simbolizava o luto fora especialmente
                preparada para a ocasião e trazia os seguintes dizeres: “Tomamos
                banho, mas a lama não sai”. No verso, outras inscrições que
                denunciaram a estigmatização: “desrespeito, preconceito,
                discriminação, descaso, intolerância, desumanidade – 05/11/2015:
                rompimento da barragem do Fundão – 05/11/2016: um ano depois
                nossa luta continua”. Desse modo, mesmo diante da hostilidade,
                as vítimas não se rendem às estratégias discursivas de obturação
                da crítica, ao contrário, transformam sua presença em um desafio
                político colocado não apenas às empresas e às instituições
                responsáveis pela reparação, mas à sociedade em geral
                confrontada com a necessidade de debater e repensar quanta
                tolerância ainda queremos cultivar frente aos danos e à
                violência da mineração.
              </p>
              <p style="text-align: center">
                <strong>Autora: Raquel Oliveira<sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Para uma discussão mais aprofundada sobre esse tema,
              ver o artigo: TEIXEIRA, Raquel Oliveira Santos. A Lama e suas
              Marcas: neoextrativismo e seus efeitos em um contexto de desastre.
              Perfiles Económicos, n. 5, 2018, pp.77 – 103. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://revistas.uv.cl/index.php/Perfiles/article/view/1237/1298"
                class="pink--text"
                >https://revistas.uv.cl/index.php/Perfiles/article/view/1237/1298</a
              >. A autora agradece ao apoio da FAPEMIG e do CNPq pelos auxílios
              que permitiram a execução das atividades de pesquisa e extensão
              universitária junto ao atingidos pela Barragem de Fundão em
              Mariana-MG.
            </p>
            <p>
              <sup>2</sup> Doutora pelo programa de Pós-Graduação em Sociologia
              da UFMG, Mestra em Sociologia e graduada em Ciências Sociais pela
              UFMG (2005). Professora adjunta do Departamento de Sociologia
              (UFMG), subcoordenadora do Grupo de Estudos em Temáticas
              Ambientais da UFMG (GESTA-UFMG).
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>
            <p>
              ALEKSIÉVITC, Svetlana. <strong>Vozes de Tchernóbil</strong>:
              crônica do futuro. São Paulo: Companhia das Letras, 2016.
            </p>
            <p>
              ANTONELLI, Mirta A.
              <strong
                >Minería Transncional y Dispositivos de Intervención en la
                cultura</strong
              >: la gestión del paradigma hegemónico de la minería responsable y
              desarollo sustentable. In: SVAMPA, Maristella; ANTONELLI, Mirta A.
              Minería transnacional, narrativas del desarollo y resistências
              sociales. Buenos Aires: Biblos, 2019.
            </p>
            <p>
              ARAOZ, Horacio Machado. <strong>Potosí, el origen</strong>:
              genealogia de la minería contemporânea. Ciudad Autonoma de Buenos
              Aires: Mardulce, 2014.
            </p>
            <p>
              FERGUSON, James. The Copperbelt in Theory: from “emerging Africa”
              to the ethnography of decline.
              <strong>Expectations of Modernity</strong>: myths and meaning of
              urban life on the Zambian Copperbelt. Los Angeles: University of
              California Press, 1999.
            </p>
            <p>
              GOFFMAN, Erwing. <strong>Estigma</strong>: notas sobre a
              manipulação da identidade deteriorada. Rio de Janeiro: Jorge Zahar
              Editor, 1981.
            </p>
            <p>
              REVET, Sandrine.
              <strong>Catégorisations, stigmatisation et reconstruction</strong
              >: comment les sinistrés vénézuéliens négocient-ils leurs
              appartenances. Réunion ERSIPAL (CREDAL), 2002. Disponível em:
              https://sciences-po.academia.edu/SandrineRevet.
            </p>
            <p>
              SILVA, Telma Carvalho). Musealização de eventos críticos: análise
              da tensão entre múltiplas narrativas da dor. In: TAMASO, Izabela;
              LIMA FILHO, Manuel Ferreira (Orgs.)
              <strong>Antropologia e patrimônio cultural</strong>: trajetórias e
              conceitos. Brasília: ABA, 2012.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-btn
            :to="{
              name: 'conteudos_tematicos_ser_atingido_trabalho_atingidos',
            }"
            color="#EDBD43"
          >
            <strong>Conhecer "O trabalhos dos Atingidos"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb.vue";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Estigma",
          href: "conteudos_tematicos_ser_atingido_estigma",
        },
      ],
    };
  },
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image4 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-4.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 0 2em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-1.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 4em;
  color: #000;
}

.border__image2 p:last-child {
  color: #000 !important;
  padding: 0 0 2em 8em;
  z-index: 20;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-5.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 0 0 2em 8em;
  color: #000;
}

.border__image3 p:last-child {
  color: #000 !important;
  padding: 0 0 2em 10em;
  z-index: 20;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 4em 0 2em 8em;
  color: #000;
}

.border__image4 p:last-child {
  color: #000 !important;
  padding: 0 0 4em 8em;
  z-index: 20;
}

.border__image4 p:nth-child(2) {
  color: #000 !important;
  padding: 0 0 0 8em;
  z-index: 20;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
